/* material design reset */

.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  background: #00AE79;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* begin: breadcrumb */
.breadcrumb {
  color: #585858;
  /* font-weight: 500; */
}
.breadcrumb.reports_page-breadcrumb {
  padding-bottom: 0;
  margin-bottom: -10px;
}
.breadcrumb > i {
  font-size: 18px;
}
/* end: breadcrumb */

/* begin: stat box */

.num {
  text-align: right;
}


.pie-box--container {
  padding-bottom: 0;
}

.pie-box--header {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.pie-box--header > * {
  flex-grow: 1;
}

.pie-box--header > .pie-box--action {
  text-align: right;
}

.pie-box--stats .stat-figure {
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
}

.pie-box--stats .stat-title {
  text-align: center;
  color: #98a6ad;
}

.pie-box--stats .stat-title > button {
  color: #98a6ad;
}

.pie-box--action {
  text-align: right;
}

/* end: pie box */

/* start: station grid */

.k-grid td, .k-grid th {
  width: 80px;
}

.mdl-grid.station-grid--container, .mdl-grid.station-grid--container > div > .mdl-grid {
  padding-top:0;
}

.ag-link-button {
  border:none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.1em;
  background-color: unset;
  margin: 0 3px;
}

.ag-link-button:hover {
  color: #49ac7a;
}

.ag-grid-title {
  font-size: 1.3em;
}

.ag-link-button:focus {
  outline:none;
}

.ag-link-button.ag-link-button--green-underline:hover {
  text-decoration-color: #49ac7a;
  -moz-text-decoration-color: #49ac7a;
}

.ag-grid-link-button--ctn {
  text-align: right;
}

.station-grid div.k-grid-header div.k-filtercell-wrapper {
  flex-flow: row wrap;
}
.station-grid div.k-grid-header div.k-filtercell-wrapper > .k-textbox,
.station-grid div.k-grid-header div.k-filtercell-wrapper > .filter-drop {
  font-weight: normal;
}


/* end: station grid */

/* start: graph grid */

/* .graph-grid .k-grid-toolbar{
  background-color: #00AE79 !important;
  color: white !important;
} */
.graph-grid-toolbar-btn{
  /* font-weight: bold; */
  font-size: 1.1em;
  /* color: white; */
  background-color: unset;
  border: none;
  margin: 0 3px;
  text-decoration: underline;
}

.graph-grid-toolbar-btn:hover{
  cursor:pointer;
  text-decoration-color: #49ac7a;
  -moz-text-decoration-color: #49ac7a;
  color: #49ac7a;
}
.graph-grid .k-grid-table tr:nth-child(even){
  background-color:rgb(0,0,0,0.03);
}
/* end: graph grid */


/* start of stat widget */
.stat-ctn {
  text-align: center;
  background: none;
  /* background-image: url('../src/stat_bg.png'); */
  border-radius: 5px;
  align-items: flex-start;
  height: fit-content;
  
}


table.stat {
  border-collapse: collapse;
  width: 100%;
  /* margin: 20px */
}

table.stat tr {
    border-radius: 5px;  
}


table.stat tr:first-child th {
  background: none;
}

table.stat tr:first-child th span {
  background:#464646;
  color: white;
  padding: 10px;
  font-size: 1.5em;
  display:inline-block;
  height: 100%;
}

table.stat td {
  background: #464646;
  color: white;
  border-bottom: 2px solid #00AE79;
  height: 40px;
  padding: 5px;
  text-align: center;
}

table.stat td:first-child {
  border-bottom: 2px solid white;
  width: 40%;
  box-shadow: 2px 0 2px #333;
  border-top-right-radius: 3px;
  background: #00AE79;
}

table.stat tr:first-child td:first-child {
  border-top-left-radius: 3px;
}

table.stat tr:last-child td:first-child {
  border-bottom-left-radius: 3px;
}

table.stat tr:first-child td:last-child {
  border-top-right-radius: 3px;
}

table.stat tr:last-child td {
  border-bottom: none;
}

table.stat tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
    /* border-bottom: none; */
}

table.stat td:last-child {
  width: 20%;
}

table.stat tr:hover{
  cursor: pointer;

}


.header{
  font-size: 1.5rem;
  cursor: pointer;
}

.header:hover{
  text-decoration: underline;
}


.menu-inEdit-buttons{
  display:flex;
  justify-content:space-around; 
  margin-top: 5px;
}

.stat .calc{
  font-weight: 200;
  font-size: 1.2rem;

}

.stat .data-value{
  font: 500;
  font-size: 1.2rem;
}

.stat .date{
  font-weight: 200;
  font-size: 1.2rem;

}
/* end of stat widget */

/* start of localization drop down list */
#header-locale{
  display: block;
}

@media only screen and (max-width: 425px){
  #header-locale{
    display: none;
  }
}

#nav-locale{
  display: none;
}

@media only screen and (max-width: 425px){
  #nav-locale{
    display: block;
    padding-left: 30px;
    padding-bottom: 5px;
  }
}

/* end of localization drop down list */

/* start of drawer */
nav .mdl-navigation__link:not(.ag-logout){
  color:white !important;
  text-transform: uppercase;
}

nav .mdl-navigation__link  {
padding: 10px 30px !important;
}

nav .mdl-navigation__link:not(.ag-logout):hover{
  color:black !important;
}
nav .mdl-navigation__link .material-icons{
  margin-right: 10px;
}


.mdl-layout__drawer{
  background-color: rgba(40, 44, 52, .85) !important;
  color:white;
  border:none;
}

.mdl-layout__drawer .mdl-navigation {
  padding-top:0;
}

.mdl-layout__drawer::-webkit-scrollbar{
  /* -webkit-box-shadow: inset 0 0 6px #464646; */
  width: 10px;
}

.mdl-layout__drawer::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid transparent !important;
  border-radius: 5px;
  background-clip: content-box;
}

/* .ag-dashboards-ctn i{
  color:white !important;
} */


/* .ag-dashboards-ctn::-webkit-scrollbar{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.ag-dashboards-ctn::-webkit-scrollbar-corner {
  background: #464646;
} */

.ag-dashboards-ctn{
  margin-left: 15px;
  /* max-height: 40%; */
  /* overflow-y: scroll !important;
  overflow-x: hidden !important; */
  padding-left: 10px;
}

/* .ag-dashboards-ctn::-webkit-scrollbar-thumb {
  background-color: #282c34;
  
}

.ag-dashboards-ctn::-webkit-scrollbar-bottom {
  background-color: #282c34; 
} */

.dashboard-item {
  display: flex;
  flex-direction: row;
  margin: 7px 10px 7px 3px;
  border-radius: 3px;
  /* background-color: rgba(255, 255, 255, 0.85); */
  /* justify-content: center; */
  justify-content: space-around;
  /* box-shadow: 2px 2px 6px #333; */
}

.dashboard-item > i{
  align-self: center;
  color: rgba(255, 255, 255, 0.85);
  margin-right: 2px;
  visibility: hidden;
}

.dashboard-item > i:hover {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* color: white !important; */
}

.dashboard-item > a {
  cursor: pointer;
  /* text-align: center; */
  width: 100%;
  margin: auto;
  border-radius: 3px;
  color:rgba(255, 255, 255, 0.80) !important;
  padding: 2px;
  padding-left: 8px;
}

.dashboard-item:has(> .active),
.dashboard-item:hover {
  background:rgba(255, 255, 255, .9) !important;
}

.dashboard-item:has(> .active) .active,
.dashboard-item:has(> .active) > i,
.dashboard-item:hover > i,
.dashboard-item:hover > a {
  color: #282c34 !important;
  visibility: visible;
}

.dashboard-item:hover > i:hover {
  color: white !important;
}

/* .ag-dashboards-ctn a:hover{ */
  /* color:white !important; */
/* } */


/* end of drawer */
/* maps */

div.map-control > div {
  height: 40px;
  width: 40px;
  vertical-align:middle;
  text-align: center;
  display:table-cell;
  color: #565656;
  cursor: pointer;
}


div.map-control > div > i {
  font-size: 2.5em;
  /* font-weight: 800; */
}



div.map-control > div:hover {
  background:#e5e5e5;
}

div.map-control > div:hover > i {
  color:black;
}

.maps.info-window {
  margin: 5px;
}

.maps.info-window span {
  font-size: large;
}

.maps.marker-dialog--form-container, .maps.map-dialog--form-container {
  padding: 30px;
}

.maps.marker-dialog--form-container .k-textbox-container, .maps.map-dialog--form-container .k-textbox-container {
  width: 100%;
}

.maps.info-window > table {
  border-collapse: collapse;
  margin-top: 10px;
}
.maps.info-window > table td {
  border-bottom: 1px solid #c5c5c5;
  width: 80px;
  padding: 3px;
}

.maps.info-window > table td > i {
  vertical-align: middle;
}

.maps.info-window > table td:first-child {
  padding-right: 10px;
  font-weight: bold;
}

.maps.info-window > table td:last-child {
  font-size: 1.2rem;
}
/* end maps */

/* start users grid */
.payment-td{
  text-align: center !important;
}
.user-grid-button{
  margin-right: 5px !important;
  
}

.user-grid div.k-grid-header div.k-filtercell-wrapper {
  flex-flow: row wrap;
}
.user-grid div.k-grid-header div.k-filtercell-wrapper > .k-textbox,
.user-grid div.k-grid-header div.k-filtercell-wrapper > .filter-drop {
  font-weight: normal;
}
.user-grid div.k-grid-header .k-filter-row th {
  padding-top: 0;
  padding-bottom: 0;
}
/* end users grid */


/* start chart table grid */
.column-label-container{
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.chart-table-view table tr:nth-child(even){
  background-color:rgb(0,0,0,0.03) !important;
}
/* end chart table grid */

/* start refresh button */
@keyframes rotate{
  from {transform: rotate(360deg); }
  to {transform: rotate(0deg);}
}

.refresh-start{
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: running;
}

/* end refresh button */
/* start weather report */


.config-btn{
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border: .1px solid rgba(0, 0, 0, 0.123) ;
  padding: 7px 10px;
  border-radius: 5px;
  width: fit-content;
}
.config-btn i{
  margin-right: 5px;
}

.conf-btn-ctn{
  -webkit-display: flex;
  -moz-display: flex;
  -ms-display: flex;
  -o-display: flex;
  display: flex;
  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
  width: 100%;
}

.config-btn:hover{
 cursor: pointer;
}

.threshold-ctn .k-textbox-container label{
  color: white;
}

.conf-hint{
  /* configuration hint text */
  font-size: '0.9em';
  font-style: 'italic';
}



.form-ctn{
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 400px){
  .conf-btn-ctn{
    display:flex;
    -webkit-display: flex;
    -moz-display: flex;
    -ms-display: flex;
    -o-display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
  }
}
/* end weather report */

/* start Station */

.station-dialog-ctn .k-textbox-container{
  width: 100%;
}

/* Station dialog save button */
.station-dialog-button{
  display: flex;
  flex-flow: wrap column;
  justify-content: flex-end;
  align-items:flex-start;
}
/* end Station */


/* start Tools (Copy Chart/Dash/Delete Records) */
.delete-records, .copy-chart-ctn .k-textbox-container, .copy-dash-ctn .k-textbox-container{
  width: 100% !important;
}

/* end Tools (Copy Chart/Dash) */


/* Range Slider */
.range-slider{
  margin-top: 60px;
  margin-bottom: 20px;
}
.range-slider .tag:first-of-type {
  border-radius: 5px 0px 0px 5px;
}
.range-slider .tag:last-of-type {
   border-radius: 0px 5px 5px 0px !important;
}
.range-slider .slider-button{
  width: 2em;
  height: 2em;
  background-color: white;
  position: absolute;
  border-radius: 2em;
  right: calc(-1.1em);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: auto;
  z-index: 10;
  cursor: ew-resize;
  user-select: none;
  }

.range-slider .tag:last-of-type>.slider-button {
  top: 0;
  right: 20px !important;
  width: 0 !important;
  border-radius: 0;
  background-color: #69AAF9 !important;
}
.range-slider .tag:last-of-type>.slider-button img {
  display: none;
}
.range-slider .tag .plant-days span{
  text-decoration: underline 1px dotted;
} 

.range-slider .tag:nth-child(odd) .date-span{
  position: absolute;
  font-weight: bold;
  top: 70px !important;
}
.range-slider .tag:last-of-type .date-span {
  cursor: default !important;
}

.range-slider span{
font-family:sans-serif !important;
}
.range-slider span .plant-days-input{
  color: #fff;
  text-align: center;
  width: 35px;
}


.date-span {
  position: absolute;
  font-weight: bold;
  top:-60px;
}

.plant-date-span {
  position: absolute;
  font-weight: bold;
  top:-2em;
  left:-20px;
  cursor: default !important;
}
/* Billling */
.ag-billstable td {
  font-weight: 500 !important;
}
.ag-billdetail td, .ag-billdetail th {
  font-size: .9em !important;
  padding: 5px !important;
  font-weight: normal !important;
}
.ag-billitems {
  width: 100%;
  font-size: .9em;
}
.ag-billitems td {
  font-style: italic;
}
.ag-billitems > thead > tr > th{
  padding: 10px 0;
}

.ag-billitems > tfoot > tr:first-child > td {
  padding-top: 10px;
}

.ag-billitems > thead > tr > th, .ag-billitems > tfoot > tr > td {
  font-weight: bold;
  font-style: normal;
}

.ag-billitems th {
  text-align: left;
}

.alerts-modal-form-inputs-wrap.payment-dialog {
  justify-content: space-between;
}

.due-bills-msg{
  display: flex; 
  justify-content:flex-end; 
  align-items:center;
}
.due-bills-msg  label{
  text-decoration: underline;
}

/* start subscription grid */

.subscription-grid-ctn div.k-grid-header div.k-filtercell-wrapper {
  flex-flow: row wrap;
}
.subscription-grid-ctn div.k-grid-header div.k-filtercell-wrapper > .k-textbox,
.subscription-grid-ctn div.k-grid-header div.k-filtercell-wrapper > .filter-drop {
  font-weight: normal;
}
.subscription-grid-ctn div.k-grid-header .k-filter-row th {
  padding-top: 0;
  padding-bottom: 0;
}
/* end subscription grid */

/* start discount grid */

.discount-grid-ctn div.k-grid-header div.k-filtercell-wrapper {
  flex-flow: row wrap;
}
.discount-grid-ctn div.k-grid-header div.k-filtercell-wrapper > .k-textbox,
.discount-grid-ctn div.k-grid-header div.k-filtercell-wrapper > .filter-drop {
  font-weight: normal;
}

fieldset{
  border-top:1px solid lightgray;
  padding:15px;
  margin: 15px;
}

fieldset legend{
  padding: 10px;
  color: gray;
}

@media screen and (min-width: 1280px){
  .dash-stat-ctn > .mdl-cell--3-col{
    width: calc(20% - 16px) !important;
  }
}

@media screen and (min-width: 1536px){
  .dash-stat-ctn > .mdl-cell--3-col{
    width: calc(16.666667% - 16px) !important;
  }
}

.annotation-del{
  display:block;
  border:none;
  color:red;
  font-size:0.7em;
  background-color:Transparent;
  padding:0;
  margin:0
}


/* Scheduler */

.k-scheduler-toolbar .k-scheduler-navigation .k-button{
  @apply p-2 rounded-3xl border-[0.5px] border-gray-100 text-slate-500 bg-white mr-4 !important;
} 

.k-scheduler-footer .k-scheduler-navigation .k-button{
  @apply p-2 rounded-3xl border-[0.5px] border-gray-100 text-slate-500 bg-white mr-4 !important;
}

.k-scheduler-toolbar .k-widget .k-button{
  @apply p-2 rounded-3xl border-[0.5px] border-gray-100 text-slate-500 bg-white mr-4 !important;
}

.k-scheduler-toolbar .k-scheduler-views .k-scheduler-views-wrapper .k-button-group .k-button{
  @apply p-2 rounded-3xl border-[0.5px] border-gray-100 text-slate-500 bg-white mr-4 !important;
}

 .k-scheduler .k-toolbar{
  @apply bg-white drop-shadow-none shadow-none !important;

}

.k-scheduler{
  @apply border-t-0 !important;
}

.k-scheduler .k-scheduler-layout .k-scheduler-head {
  @apply bg-green-50 rounded-t-3xl border border-gray-100 !important;

}
