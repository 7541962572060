
@keyframes spinner-box {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }

.spinner-box div {
    position: absolute;
    width: 36px;
    height: 36px;
    border: 4px solid white;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .spinner-box div {
    animation: spinner-box 1s linear infinite;
    top: 50px;
    left: 50px
  }
  .spinner-rolling {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
  }
  .spinner-box {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .spinner-box div { box-sizing: content-box; }