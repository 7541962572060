@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,400i,500,600');

body {
  font-family: 'Poppins';
}

.ag_logo {
  max-width: 100px;
  margin-left: 10px;
}
/* .mdl-layout__header-row {
  height: 84px;
} */

/* custom colors */

.mdl-layout__header {
  background: #49AC7A !important;
}

.k-button.k-primary {
  border-color: #49AC7A !important;
  background-color: #49AC7A !important;
  color: white !important;
}

.k-switch-on .k-switch-track {
  background-color: rgba(73,172,122, .5) !important;
}

.k-switch-on .k-switch-thumb {
  background-color: #49AC7A !important;
}

.k-focus .k-input-solid:focus {
  border-color: #49AC7A;
}

.k-focus .k-picker-solid:focus {
  border-color: #49AC7A;
}

.k-focus .k-input-flat:focus {
  border-color: #49AC7A;
}

.k-focus .k-input-outline:focus {
  border-color: #49AC7A;
}

.k-focus .k-picker-outline:focus {
  border-color: #49AC7A;
}

.k-floating-label-container.k-focus:focus{
  border-color: #49AC7A  !important;
}


.k-picker-solid:focus, .k-picker-solid.k-focus{
  border-color: #49AC7A !important;
  border-top: transparent;
}



.k-input-solid:focus-within{
  border-color: #49AC7A !important;
  border-top: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: #49ac7a !important;
}

.k-calendar.k-calendar-range .k-range-start .k-link, .k-calendar.k-calendar-range .k-range-end .k-link {
  background-color: #49AC7A !important;
}

.k-calendar .k-state-selected .k-link, .k-checkbox:checked {
  border-color: #49AC7A !important;
  background-color: #49AC7A !important;
}

.k-calendar.k-calendar-range .k-range-start, .k-calendar.k-calendar-range .k-range-end, .k-calendar.k-calendar-range .k-range-mid {
  background-image: linear-gradient(transparent 1px, rgba(73,172,122,0.25) 1px, rgba(73,172,122,0.25) calc(100% - 1px), transparent calc(100% - 1px)) !important;
}

.k-calendar .k-content .k-today {
  color: #49AC7A !important;
}

.k-floating-label-container {
 width: 100% !important; 
}

.k-floating-label-container.k-focus > .k-label {
  color: #49AC7A !important;
}

.mdl-button.mdl-button--colored {
  color: #49AC7A !important;
}



/* end custom colors */
.chart-card.mdl-card {
  width: 100%;
  font-size: 14px;
}
.chart-card > .mdl-card__title {
  /*color: #fff;*/
  height: 400px;
  /*background: url('../assets/demos/welcome_card.jpg') center / cover;*/
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
      -ms-align-items: stretch;
          align-items: stretch;
  padding: 0;
  padding-bottom: 5px;
}

.chart-card > .mdl-card__actions {
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-direction: row;
          flex-wrap: nowrap;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-justify-content: space-between;
          justify-content: space-between;
  border-top: none !important;
  border-bottom: 1px solid rgba(0,0,0,.1);
  font-weight: 600;
}

.add-tab-button {
	color:white;
  position: relative;
  margin: 0;
  border: none;
  padding-top: 8px;
  float: left;
  position: relative;
  display: block;
  -webkit-flex-grow: 0;
      -ms-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
      flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  overflow: hidden;
}

.ag-add-button {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  background: rgba(73,172,122, .5) !important;
}

.ag-add-widget-button {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 9999;
}

.ag-add-widget-button button {
  background: rgba(73,172,122, .5) !important;
}

.ag-add-widget-button button:hover {
  background: rgba(73,172,122, 1) !important;
}

.ag-add-widget-button button:hover i{
  color:white;
}

.ag-add-button:hover {
 background: rgba(73,172,122,1) !important; 
 color: white !important;
}

.popup-content {
    padding: 10px;
    color: #787878;
    background-color: #fcf7f8;
    border: 1px solid rgba(0,0,0,.05);
  }

  .mdl-menu__item {
    line-height: 30px;
    /*font-size: 20px;*/
    height: 30px;
    /*color: blue;*/
  }

.calculations-row {
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: stretch;
      -ms-justify-content: stretch;
          justify-content: stretch;
  margin: 0;
  padding: 0 8px;
}

.sensors-row {
  -webkit-justify-content: space-between;
      -ms-justify-content: space-between;
          justify-content: space-between;
  margin: 0;

}


.k-list-container, .k-daterangepicker-wrap, .k-animation-container {
  z-index: 10010 !important;
  /*position: absolute;*/
}



.k-picker-solid, .k-input-solid {
  background-color: transparent !important;
}

/* Fix color picker height after kendo version upgrade */
.k-input-md{
  height: 38px;
}


.modal-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex:1;
      -ms-flex:1;
          flex:1;
  /*align-items: flex-end;*/
  /* justify-content: space-between; */
}

.modal-calendar-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  /*justify-content: space-between;*/
  margin: 10px;
}

.modal-form-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;

  -webkit-justify-content: space-between;
      -ms-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin: 10px;
}

.modal-form-inputs-wrap{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  
}

.modal-form-inputs-wrap-row{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.modal-form-inputs-wrap-row > *{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  min-width: 250px;
}


.modal-form-wrap > *, .modal-calendar-wrap > *{
  /* width: 100% !important; */
  -webkit-align-items: stretch;
      -ms-align-items: stretch;
          align-items: stretch;
}

.alerts-modal-form-inputs-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal-form-inputs-wrap > * {
  -webkit-flex: 1 auto;
      -ms-flex: 1 auto;
          flex: 1 auto;
}

.modal-form-row {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  /* justify-content: stretch; */
}

.modal-form-row > * {
  -webkit-flex: 1 200px;
      -ms-flex: 1 200px;
          flex: 1 200px;
}

.modal-form-row .graphs-multiselect div{
  overflow-y: scroll;
  height: 65px !important;
}

.alerts-table td:nth-child(3){
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 77px;
  border-bottom: 0;
}

.ag-switch-container {
  min-width: 200px;
}


.k-daterangepicker-wrap {
  /*width: 50% !important;*/
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.k-daterangepicker-wrap span:nth-child(2) {
  display: none;
}

.k-daterangepicker-wrap > * {
  min-width: 150px !important;
}


.modal-widget-dialog-inputs-wrap {
  display: -webkit-flex !important;
  display: -ms-flex !important;
  display: flex !important;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
      -ms-align-items: stretch;
          align-items: stretch;
}

.modal-widget-dialog-inputs-wrap.modal-stat-wrap > span{
  width: 100% !important;
}

.k-dialog {
  height: 100%;
  width: 100%;
}

/* Begin: Graph chips*/

.ag-graphchip-container {
  list-style: none;
  display:-webkit-flex;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: flex-start;
      -ms-align-items: flex-start;
          align-items: flex-start;
  padding: 0;
  /* height: 120px; */
  overflow: scroll;
}

.ag-graphchip-add {
  padding:0 !important;
}

.ag-graphchip-add:hover {
  background:#f0f0f0 !important;
}

.ag-graphchip-add .mdl-chip__contact {
  margin: 0 !important;
}
.ag-graphchip-item {
  margin: 2px;
  /* box-sizing: border-box; */
}

.ag-graphchip-item > * {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding-right: 5px;
}

.ag-graphchip-item .mdl-chip--contact {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-direction: row;
          flex-wrap: nowrap;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  /* box-sizing: border-box; */
  /* color:white; */
}

.ag-graphchip-item .mdl-chip__contact {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
        flex-direction: row;
          flex-wrap: nowrap;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  color:white;
}

.ag-graphchip-item:hover > span {
  background:#e0e0e0;
  cursor:pointer;
}

.ag-graphchip-item .mdl-chip .mdl-chip__contact i {
   vertical-align:sub; 
}

.ag-graphchip-item .mdl-chip {
  background:  #f0f0f0;
}

.ag-graphchip-item .mdl-chip__action:hover {
  color:#cc0000;
  opacity:1;
}

/* End: Graph chips*/

.ag-stations-grid {
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
}


.ag-station-card.mdl-card {
  width: 100%;
  height: auto;
  min-height: auto !important;
  background: #fff;
}
.ag-station-card > .mdl-card__title {
  padding-bottom: 0px; 
  -webkit-align-items: flex-start;
      -ms-align-items: flex-start;
          align-items: flex-start;
-webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
          justify-content: space-between;
}
.ag-card-text{
  padding-top:0px;
}
.ag-card-text > img {
  width: 22px;
  margin-bottom: 8px;
}
.ag-card-text > span{
  margin-left: 8px;
}
.ag-station-card > .mdl-card__title > .ag-card-menu > a{
  overflow: visible;
}
.ag-station-card > .mdl-card__title > h4 {
  margin-top: 0;
  color: black;
}
.ag-station-search{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
}

.ag-station-card > .mdl-card__title > .ag-card-menu{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  border-color: rgba(128,128,128,.3);
}
.ag-station-card > .mdl-card__actions > .material-icons {
  padding-right: 10px;
}
.ag-station-card > .mdl-card__title,
.ag-station-card > .mdl-card__actions,
.ag-station-card > .mdl-card__actions > .mdl-button {
  color: #757575;
}

.ag-range-btn {
  margin: 10px 3px;
}

.ag-range-selected {
  border: 1px solid #1ce4b1;
}



.login-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  height: 100%;
  background: rgba(0,0,0,.3);
}

.login-container .k-textbox-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  width: auto;
}

.login-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 20px;
  /* margin-top: 10%;
  margin-left: 35%; */
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.login-card .mdl-card__title-text {
  text-align: center;
    -webkit-align-self: center;
    -ms-align-self: center;
    align-self: center;
    margin-top: 10px;
}

.login-card .mdl-textfield {
  margin: 0 auto;
  -webkit-align-self: center;
  -ms-align-self: center;
      align-self: center;
  width: 100%;
}

.login-card .mdl-card__actions {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

/* .ag-spacer {
  flex-grow: 1;
} */

.ag-dashboards-ctn {
  -webkit-flex-grow: 1;
      -ms-flex-grow: 1;
          flex-grow: 1;
  /* display: -webkit-flex;
  display: -ms-flex;
  display: flex; */
  /* -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; */
  /* overflow:scroll; */
}

.ag-dashboards-ctn > span {
  padding: 8px 30px 8px 50px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  /* justify-content: space-between; */
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
}

.ag-dashboards-ctn > span.is-active {
  background: #e5e5e5; 
}

.ag-dashboards-ctn > span a {
  -webkit-flex-grow: 1;
      -ms-flex-grow: 1;
          flex-grow: 1;
}

.ag-dashboards-ctn > span a.is-active {
  color: #49AC7A;
}

.ag-dashboards-ctn > span:hover {
  background: #e5e5e5; 
}

.ag-dashboards-ctn i.material-icons {
  /* color: #757575; */
  cursor: pointer;
}

/* .ag-dashboards-ctn i.material-icons:hover {
  color: blue
} */


.ag-dashboards-ctn a {
  color: #757575;
  text-decoration: none;
}

/* .ag-dashboards-ctn a:hover {
  background: #e5e5e5;
}  */

.ag-logout-ctn {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: stretch;
      -ms-align-items: stretch;
          align-items: stretch;
  padding-bottom: 16px;
}

a.ag-logout {
  padding: 10px 30px;
  color: #ee4343;
}

.ag-logout > .material-icons {
  margin-right: 10px;
}

.ag-logout:hover {
  cursor: pointer;
  background: #e5e5e5;

}


.ag-chart-popup ul {
  list-style: none;
  width: 150px;
  margin: 0;
  padding: 0;
}

.ag-chart-popup li {
  padding: 0 16px ;
  cursor: pointer;
  line-height: 30px;
}

.ag-chart-popup li:hover {
  background: #e5e5e5;
}

.ag-chart-popup a {
  color:black;
  text-decoration: none;
}


.amcharts-amexport-label {
  color: #49AC7A;
}


/*begin: stat widgets*/

.stat-card-ctn {
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

div.stat-card {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  /* width: 300px; */
  background: white;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
          box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  margin: 10px
}

.stat-card > .title {
  text-align:center;
  font-size: 1.7em;
  border-bottom: 1px solid #1EB980;
  padding-bottom: 10px;
  padding-top:10px;
  /* font-family: Abel;  */
}

.stat-card > .title > span.menu {
  float:right;
  margin-right:10px;
  color: gray;
}


div.stat-card > .item {
  display:-webkit-flex;
  display:-ms-flex;
  display:flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-justify-content: space-between;
          justify-content: space-between;
  margin: 8px 15px;
  padding: 8px 5px;
  /* background: #f2f2f2; */
  border-bottom: 2px solid #f2f2f2;
}

div.stat-card > .item:hover {
  border-bottom: 2px solid #0EAD69;
  cursor:pointer;
}

.stat-card > .item > .title-col.blue {
    border-left: 3px solid #0EAD69;
    padding-left: 5px;
}

.stat-card > .item > .title-col.red {
    border-left: 3px solid #4DFB8F;
    padding-left: 5px;
}

.stat-card > .item > .title-col.green {
    border-left: 3px solid #1CE4B1;
    padding-left: 5px;
}

.stat-card > .item > .title-col.amber {
    border-left: 3px solid #03440C;
    padding-left: 5px;
}

.stat-card > .item .item-title {
  /* font-family:'Exo 2'; */
  font-size: 1.2em;
  
}

.stat-card > .item .calc {
  color:gray;
  /* font-family:Abel; */
}


.stat-card > .item .value {
  /* font-family: Oxygen; */
  font-size: 1.3em;
}

.stat-card > .item .date {
  /* font-family: Abel; */
  color:gray;
  
}

button.k-button.delete {
  color:red;
}

.stat-loading-indicator {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
}

/*end: stat widgets*/

/*begin: animated loading icon */
/* .modal-wrap > * {
  flex:1;
} */
.loading-icon-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  /* flex:0; */
  /* justify-content: stretch; */
}

.modal-spacer {
  -webkit-flex:1;
      -ms-flex:1;
          flex:1;
}

.loading-indicator-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  height: 20vh;
}

/* loading indicator tiny */

@keyframes lds-bars-tiny {
  0% {
    opacity: 1;
    height: 10px;
  }
  50% {
    opacity: 0.5;
    height: 4px;
  }
  100% {
    opacity: 1;
    height: 10px;
  }
}
@-webkit-keyframes lds-bars-tiny {
  0% {
    opacity: 1;
    height: 10px;
  }
  50% {
    opacity: 0.5;
    height: 4px;
  }
  100% {
    opacity: 1;
    height: 10px;
  }
}
.lds-bars.tiny {
  height: 10px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items:flex-end;
      -ms-align-items:flex-end;
          align-items:flex-end;
  /* background: rgba(255, 255, 255, .8); */
  background: transparent;
}
.lds-bars.tiny div {
  width: 4px;
  margin:1px;
  -webkit-animation: lds-bars-tiny 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-bars-tiny 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-bars.tiny div:nth-child(1) {
  background:white;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-bars.tiny div:nth-child(2) {
  background: white;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.lds-bars.tiny div:nth-child(3) {
  background: white;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.lds-bars.tiny div:nth-child(4) {
  background: white;
}


/* loading indicator small */

@keyframes lds-bars-small {
  0% {
    opacity: 1;
    height: 20px;
  }
  50% {
    opacity: 0.5;
    height: 10px;
  }
  100% {
    opacity: 1;
    height: 20px;
  }
}
@-webkit-keyframes lds-bars-small {
  0% {
    opacity: 1;
    height: 50px;
  }
  50% {
    opacity: 0.5;
    height: 20px;
  }
  100% {
    opacity: 1;
    height: 50px;
  }
}
.lds-bars.small {
  height: 20px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items:flex-end;
      -ms-align-items:flex-end;
          align-items:flex-end;
  /* background: rgba(255, 255, 255, .8); */
  background: transparent;
}
.lds-bars.small div {
  width: 8px;
  margin:1px;
  -webkit-animation: lds-bars-small 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-bars-small 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-bars.small div:nth-child(1) {
  background: #346b21;
  /* background:gray; */
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-bars.small div:nth-child(2) {
  background: #94d137;
  /* background: gray; */
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.lds-bars.small div:nth-child(3) {
  background: #b3e46e;
  /* background:gray; */
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.lds-bars.small div:nth-child(4) {
  /* background:gray; */
  background: lightgray;
}


/* loading indicator large */

@keyframes lds-bars-large {
  0% {
    opacity: 1;
    height: 40px;
  }
  50% {
    opacity: 0.5;
    height: 20px;
  }
  100% {
    opacity: 1;
    height: 40px;
  }
}
@-webkit-keyframes lds-bars-large {
  0% {
    opacity: 1;
    height: 40px;
  }
  50% {
    opacity: 0.5;
    height: 20px;
  }
  100% {
    opacity: 1;
    height: 40px;
  }
}
.lds-bars.large {
  height: 40px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items:flex-end;
      -ms-align-items:flex-end;
          align-items:flex-end;

}
.lds-bars div {
  width: 15px;
  margin:1px;
  margin-left: 1px;
  -webkit-animation: lds-bars-large 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-bars-large 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-bars div:nth-child(1) {
  /* background: #346b21; */
  background:gray;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-bars div:nth-child(2) {
  /* background: #94d137; */
  background: gray;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.lds-bars div:nth-child(3) {
  /* background: #b3e46e; */
  background:gray;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.lds-bars div:nth-child(4) {
  background:gray;
/* background: lightgray; */
}

/*end: animated loading icon */

/*begin: amcharts popup  */

.ampopup-title {
  display: none;
}

.ampopup-content {
  display: -webkit-flex !important;
  display: -ms-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
      -ms-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-align-items: center;
          align-items: center;
  background: transparent !important;
  background: rgba(255, 255, 255, 0.7) !important;
}


/* end: amcharts popup */

/* begin: notifications */

ul.notifications details {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction:column;
      -ms-flex-direction:column;
          flex-direction:column;
}

ul.notifications li {
  border-bottom: 1px solid gray;
}

ul.notifications li:first-child {
  border-top: 1px solid gray;
}

ul.notifications summary {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content:space-between;
      -ms-justify-content:space-between;
          justify-content:space-between;
  cursor: pointer;
  height: 30px;
  -webkit-align-items:center;
      -ms-align-items:center;
          align-items:center;
  padding: 10px;
}

ul.notifications summary > .title {
  -webkit-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

ul.notifications summary > .time {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

ul.notifications summary > .time {
  color:gray;
  font-style:italic;
  text-align: right;

}

ul.notifications li:hover {
  border-bottom: 1px solid #1EB980;

}

ul.notifications summary::-webkit-details-marker {
  display:none;
}

ul.notifications {
  list-style: none;
  padding:0;
}

ul.notifications .title .type {
  margin-right:16px;
  float:left;
  font-size: 1.5em;
}

ul.notifications .title > .type.w {
  color:#ffbf00;
}

ul.notifications .title > .type.c {
  color:red;
}

ul.notifications .title > .type.i {
  color:deepskyblue;
  padding: 4px;
}


ul.notifications .title  {
  display:-webkit-flex;
  display:-ms-flex;
  display:flex;
  -webkit-align-items:center;
      -ms-align-items:center;
          align-items:center;
}

ul.notifications .title.unread, .title.unread + .time {
  font-weight: bold;
  color:black;
}


/* end: notifications */



/* 
  ********Responsive styles ****************
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
  .k-dialog {
    height: 50%;
    width: 50%;
  }

}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .k-dialog {
    height: 80%;
    width: 600px;
  }

  .add-station-dialog .k-dialog {
    height: 300px !important;
  }
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
 
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

