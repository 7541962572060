.tip-box {
  border-top: 1px solid #FFBE00;
  border-bottom: 1px solid #FFBE00;
  overflow: hidden;
  cursor: pointer;
  margin-top: 10px;
  min-height: 30px;
}

.tip-box .tip-bulb {
  vertical-align: middle;
  color: #FFBE00;
  font-size: 27px;
  position: absolute;
  margin: 2px;
}

.tip-box .tip-bulb-red {
  color: red;
}

.tip-box .text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  left: 35px;
  top: 14px;
}

.tip-box .narrow {
  font-size: .8em;
  text-transform: uppercase;
}

.tip-box  .text.full {
  position: static;
  padding-left: 35px;
  top: 0;
}

.tip-box  .text > .line.two {
  margin-top: -1px;
}

.top-ctn {
  opacity: .7;
  position: absolute;
  top: -5px;
}

.top-ctn:hover {
  opacity: 1;
}
